<template>
  <div>
    <!-- DESKTOP -->
    
    <div class="news-grid__desktop" v-if="!isMobile" >
      <div class="mb-xl-index-grid">        
          <v-row>
            <v-col class="mb-0 pb-0" cols="12">
              <p class="text-title-news-grid">Actualidad</p>
            </v-col>

            <v-col cols="3" v-if="newsGrid[pagination.page - 1][0]" class="pb-0">
            <!-- <v-col cols="3" v-if="newsGrid[pagination.page - 1][0] && this.userInfo.document_number === newsGrid[pagination.page - 1][0].cedulas" class="pb-0"> -->
              <v-card
                elevation="1"
                class="mx-auto rounded-lg news-grid__hover-card"
                max-width="300"
              >
                <v-img
                  class="rounded-lg"
                  height="200"
                  :src="newsGrid[pagination.page - 1][0].img_small"
                  style="
                    border-bottom-right-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                  "
                ></v-img>

                <v-card-title class="text-small pb-2 pt-5">
                  {{ newsGrid[pagination.page - 1][0].post_date }}
                  <v-spacer></v-spacer>
                  <v-chip text-color="#fff" color="#466BE3" small>
                    {{ newsGrid[pagination.page - 1][0].tagName }}
                  </v-chip>
                </v-card-title>

                <v-card-title class="text-title text-title-1 pt-2 pb-1">{{
                  newsGrid[pagination.page - 1][0].post_title
                }}</v-card-title>

                <v-card-text
                  style="height: 60px; color: #626262 !important"
                  class="text-basic mb-2 mt-2"
                >
                  <div
                    v-html="newsGrid[pagination.page - 1][0].description"
                  ></div>
                </v-card-text>
                <v-card-actions>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :to="{
                        name: 'newsDetails',
                        params: { id: newsGrid[pagination.page - 1][0].post_id },
                      }"
                      class="mb-4"
                      block
                      style="color: #fff"
                      rounded
                      :style="{
                        'background-color': hover ? '#324CA1' : '#466BE3',
                      }"
                      >VER MÁS</v-btn
                    >
                  </v-hover>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-row>
                <v-col cols="12" v-if="newsGrid[pagination.page - 1][1]">
                  <v-card
                    height="208"
                    class="mx-auto rounded-lg news-grid__hover-card"
                    elevation="1"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-avatar size="208" tile>
                        <v-img
                          class="rounded-lg"
                          :src="newsGrid[pagination.page - 1][1].img_small"
                          style="
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                          "
                        ></v-img>
                      </v-avatar>
                      <div style="text-align: initial">
                        <v-card-title class="text-small pb-2 pt-5">
                          {{ newsGrid[pagination.page - 1][1].post_date }}
                          <v-spacer></v-spacer>
                          <v-chip text-color="#fff" color="#466BE3" small>
                            {{ newsGrid[pagination.page - 1][1].tagName }}
                          </v-chip>
                        </v-card-title>

                        <v-card-title
                          class="text-title text-title-1 pt-0 pb-0"
                          v-text="newsGrid[pagination.page - 1][1].post_title"
                        ></v-card-title>
                        <v-card-text
                          style="height: 60px; color: #626262 !important"
                          class="text-basic"
                        >
                          <div>
                            {{ newsGrid[pagination.page - 1][1].post_excerpt }}
                          </div>
                        </v-card-text>

                        <v-card-actions class="pt-0">
                          <v-hover v-slot="{ hover }">
                            <v-btn
                              :to="{
                                name: 'newsDetails',
                                params: {
                                  id: newsGrid[pagination.page - 1][1].post_id,
                                },
                              }"
                              class="mb-4"
                              block
                              style="color: #fff"
                              rounded
                              :style="{
                                'background-color': hover ? '#324CA1' : '#466BE3',
                              }"
                              >VER MÁS</v-btn
                            >
                          </v-hover>
                        </v-card-actions>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="newsGrid[pagination.page - 1][2]">
                  <v-card
                    height="208"
                    class="mx-auto rounded-lg news-grid__hover-card"
                    elevation="1"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-avatar size="208" tile>
                        <v-img
                          class="rounded-lg"
                          :src="newsGrid[pagination.page - 1][2].img_small"
                          style="
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                          "
                        ></v-img>
                      </v-avatar>
                      <div style="text-align: initial">
                        <v-card-title class="text-small pb-2 pt-5">
                          {{ newsGrid[pagination.page - 1][2].post_date }}
                          <v-spacer></v-spacer>
                          <v-chip text-color="#fff" color="#466BE3" small>
                            {{ newsGrid[pagination.page - 1][2].tagName }}
                          </v-chip>
                        </v-card-title>
                        <v-card-title
                          class="text-title text-title-1 pt-0 pb-0"
                          v-text="newsGrid[pagination.page - 1][2].post_title"
                        ></v-card-title>
                        <v-card-text
                          style="height: 60px; color: #626262 !important"
                          class="text-basic"
                        >
                          <div>
                            {{ newsGrid[pagination.page - 1][2].post_excerpt }}
                          </div>
                        </v-card-text>

                        <v-card-actions>
                          <v-hover v-slot="{ hover }">
                            <v-btn
                              :to="{
                                name: 'newsDetails',
                                params: {
                                  id: newsGrid[pagination.page - 1][2].post_id,
                                },
                              }"
                              class="mb-4"
                              block
                              style="color: #fff"
                              rounded
                              :style="{
                                'background-color': hover ? '#324CA1' : '#466BE3',
                              }"
                              >VER MÁS</v-btn
                            >
                          </v-hover>
                        </v-card-actions>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" v-if="newsGrid[pagination.page - 1][3]" class="pb-0">
              <v-card
                elevation="1"
                class="mx-auto rounded-lg news-grid__hover-card"
                max-width="300"
              >
                <v-img
                  class="rounded-lg"
                  height="200"
                  :src="newsGrid[pagination.page - 1][3].img_small"
                  style="
                    border-bottom-right-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                  "
                ></v-img>

                <v-card-title class="text-small pb-2 pt-5">
                  {{ newsGrid[pagination.page - 1][3].post_date }}
                  <v-spacer></v-spacer>
                  <v-chip text-color="#fff" color="#466BE3" small>
                    {{ newsGrid[pagination.page - 1][3].tagName }}
                  </v-chip>
                </v-card-title>

                <v-card-title class="text-title text-title-1 pt-2 pb-1">{{
                  newsGrid[pagination.page - 1][3].post_title
                }}</v-card-title>

                <v-card-text
                  style="height: 60px; color: #626262 !important"
                  class="text-basic mb-2 mt-2"
                >
                  <div>
                    {{ newsGrid[pagination.page - 1][3].post_excerpt }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :to="{
                        name: 'newsDetails',
                        params: { id: newsGrid[pagination.page - 1][3].post_id },
                      }"
                      class="mb-4"
                      block
                      style="color: #fff"
                      rounded
                      :style="{
                        'background-color': hover ? '#324CA1' : '#466BE3',
                      }"
                      >VER MÁS</v-btn
                    >
                  </v-hover>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div class="text-center" style="width: 100%">
                <v-pagination
                  circle
                  v-model="pagination.page"
                  :length="pagination.length"
                  :total-visible="pagination.totalVisible"
                  prev-icon="mdi-chevron-left-circle-outline"
                  next-icon="mdi-chevron-right-circle-outline"
                  class="news-grid__pagination"
                  color="#466be3"
                >
                </v-pagination>
              </div>
            </v-col>
          </v-row>
        
      </div>

      <div class="mb-sm-index-grid">
        <!-- <v-col cols="12"> -->
        <hooper
          ref="carousel_mobile"
          :itemsToShow="1"
          :centerMode="true"
          style="height: 350px"
          class="mb-sm-news-main"
          v-if="newsGrid.length > 0"
          :progress="true"
          :autoPlay="true"
          :playSpeed="5000"
        >
          <slide v-for="data in newsGrid" :key="data.img_full">
            <v-card
              elevation="0"
              color="#f6f9fe"
              class="ml-0 mr-3 mb-5 mb-sm-news-main"
              height="330"
            >
              <v-img
                class="d-flex align-end"
                height="330"
                :src="data.img_full"
                gradient="0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)"
              >
                <v-row class="mx-0 px-0">
                  <v-col class="mx-0 px-0" cols="1">
                    <v-icon @click="slidePrev" dark> mdi-chevron-left </v-icon>
                  </v-col>
                  <v-col class="mx-0 px-0" cols="10">
                    <div class="ml-4 mr-4">
                      <div class="d-flex">
                        <label
                          class="ligth-text text-tag-sm text-left pb-2 mb-0"
                        >
                          {{ data.tagName }}
                        </label>
                        <label class="ligth-text text-tag-sm ml-auto">
                          {{ data.post_date }}
                        </label>
                      </div>

                      <p
                        class="ligth-text title-banner-ligth-sm text-1-1-lines mb-1"
                      >
                        {{ data.post_title }}
                      </p>

                      <div
                        class="text-left text-2-lines ligth-text text-description-sm mb-0"
                        v-html="data.description"
                      ></div>

                      <v-layout justify-start>
                        <v-btn
                          :to="{
                            name: 'newsDetails',
                            params: { id: data.post_id },
                          }"
                          class="mb-8"
                          color="#fff"
                          style="color: #3957b9"
                          rounded
                        >
                          <strong> ver más </strong>
                        </v-btn>
                      </v-layout>
                    </div>
                  </v-col>
                  <v-col cols="1" class="mx-0 px-0">
                    <v-icon @click="slideNext" dark> mdi-chevron-right </v-icon>
                  </v-col>
                </v-row>
              </v-img>
            </v-card>
          </slide>
          <hooper-progress
            style="margin-top: -8px; width: 96%"
            slot="hooper-addons"
          ></hooper-progress>
        </hooper>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="news-grid__mobile" v-else>
      <div class="mb-xl-index-grid">
        <v-row align="center" justify="end">
          <v-col class="mb-0 pb-0" cols="12">
            <p class="text-title-news-grid">Actualidad</p>
          </v-col>
          <v-col cols="3">
            <v-card
              elevation="1"
              class="mx-auto rounded-lg news-grid__hover-card"
              max-width="300"
            >
              <v-img
                class="rounded-lg"
                height="220"
                :src="listView[0].img_small"
                style="
                  border-bottom-right-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                "
              ></v-img>

              <v-card-title class="text-small pb-2 pt-5">
                {{ listView[0].post_date }}
                <v-spacer></v-spacer>
                <v-chip text-color="#fff" color="#466BE3" small>
                  {{ listView[0].tagName }}
                </v-chip>
              </v-card-title>

              <v-card-title class="text-title text-title-1 pt-2 pb-1">{{
                listView[0].post_title
              }}</v-card-title>

              <v-card-text
                style="height: 60px; color: #626262 !important"
                class="text-basic mb-2 mt-2"
              >
                <div v-html="listView[0].description"></div>
              </v-card-text>
              <v-card-actions>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :to="{
                      name: 'newsDetails',
                      params: { id: listView[0].post_id },
                    }"
                    class="mb-4"
                    block
                    style="color: #fff"
                    rounded
                    :style="{
                      'background-color': hover ? '#324CA1' : '#466BE3',
                    }"
                    >VER MÁS</v-btn
                  >
                </v-hover>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-card
                  height="215"
                  class="mx-auto rounded-lg news-grid__hover-card"
                  elevation="1"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar size="215" tile>
                      <v-img
                        class="rounded-lg"
                        :src="listView[1].img_small"
                        style="
                          border-top-right-radius: 0 !important;
                          border-bottom-right-radius: 0 !important;
                        "
                      ></v-img>
                    </v-avatar>
                    <div style="text-align: initial">
                      <v-card-title class="text-small pb-2 pt-5">
                        {{ listView[1].post_date }}
                        <v-spacer></v-spacer>
                        <v-chip text-color="#fff" color="#466BE3" small>
                          {{ listView[1].tagName }}
                        </v-chip>
                      </v-card-title>

                      <v-card-title
                        class="text-title text-title-1 pt-0 pb-0"
                        v-text="listView[1].post_title"
                      ></v-card-title>
                      <v-card-text
                        style="height: 60px; color: #626262 !important"
                        class="text-basic"
                      >
                        <div>
                          {{ listView[1].post_excerpt }}
                        </div>
                      </v-card-text>

                      <v-card-actions class="pt-0">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            :to="{
                              name: 'newsDetails',
                              params: { id: listView[1].post_id },
                            }"
                            class="mb-4"
                            block
                            style="color: #fff"
                            rounded
                            :style="{
                              'background-color': hover ? '#324CA1' : '#466BE3',
                            }"
                            >VER MÁS</v-btn
                          >
                        </v-hover>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  height="215"
                  class="mx-auto rounded-lg news-grid__hover-card"
                  elevation="1"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar size="215" tile>
                      <v-img
                        class="rounded-lg"
                        :src="listView[2].img_small"
                        style="
                          border-top-right-radius: 0 !important;
                          border-bottom-right-radius: 0 !important;
                        "
                      ></v-img>
                    </v-avatar>
                    <div style="text-align: initial">
                      <v-card-title class="text-small pb-2 pt-5">
                        {{ listView[2].post_date }}
                        <v-spacer></v-spacer>
                        <v-chip text-color="#fff" color="#466BE3" small>
                          {{ listView[2].tagName }}
                        </v-chip>
                      </v-card-title>
                      <v-card-title
                        class="text-title text-title-1 pt-0 pb-0"
                        v-text="listView[2].post_title"
                      ></v-card-title>
                      <v-card-text
                        style="height: 60px; color: #626262 !important"
                        class="text-basic"
                      >
                        <div>
                          {{ listView[2].post_excerpt }}
                        </div>
                      </v-card-text>

                      <v-card-actions>
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            :to="{
                              name: 'newsDetails',
                              params: { id: listView[2].post_id },
                            }"
                            class="mb-4"
                            block
                            style="color: #fff"
                            rounded
                            :style="{
                              'background-color': hover ? '#324CA1' : '#466BE3',
                            }"
                            >VER MÁS</v-btn
                          >
                        </v-hover>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-card
              elevation="1"
              class="mx-auto rounded-lg news-grid__hover-card"
              max-width="300"
            >
              <v-img
                class="rounded-lg"
                height="210"
                :src="listView[3].img_small"
                style="
                  border-bottom-right-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                "
              ></v-img>

              <v-card-title class="text-small pb-2 pt-5">
                {{ listView[3].post_date }}
                <v-spacer></v-spacer>
                <v-chip text-color="#fff" color="#466BE3" small>
                  {{ listView[3].tagName }}
                </v-chip>
              </v-card-title>

              <v-card-title class="text-title text-title-1 pt-2 pb-1">{{
                listView[3].post_title
              }}</v-card-title>

              <v-card-text
                style="height: 60px; color: #626262 !important"
                class="text-basic mb-2 mt-2"
              >
                <div>
                  {{ listView[3].post_excerpt }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :to="{
                      name: 'newsDetails',
                      params: { id: listView[3].post_id },
                    }"
                    class="mb-4"
                    block
                    style="color: #fff"
                    rounded
                    :style="{
                      'background-color': hover ? '#324CA1' : '#466BE3',
                    }"
                    >VER MÁS</v-btn
                  >
                </v-hover>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="mb-sm-index-grid">
        <!-- <v-col cols="12"> -->
        <hooper
          ref="carousel_mobile"
          :itemsToShow="1"
          :centerMode="true"
          style="height: 350px"
          class="mb-sm-news-main"
          v-if="listView.length > 0"
          :progress="true"
          :autoPlay="true"
          :playSpeed="5000"
        >
          <slide v-for="data in listView" :key="data.img_full">
            <v-card
              elevation="0"
              color="#f6f9fe"
              class="ml-0 mr-3 mb-5 mb-sm-news-main"
              height="330"
            >
              <v-img
                class="d-flex align-end"
                height="330"
                :src="data.img_full"
                gradient="0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)"
              >
                <v-row class="mx-0 px-0">
                  <v-col class="mx-0 px-0" cols="1">
                    <v-icon @click="slidePrev" dark> mdi-chevron-left </v-icon>
                  </v-col>
                  <v-col class="mx-0 px-0" cols="10">
                    <div class="ml-4 mr-4">
                      <div class="d-flex">
                        <label
                          class="ligth-text text-tag-sm text-left pb-2 mb-0"
                        >
                          {{ data.tagName }}
                        </label>
                        <label class="ligth-text text-tag-sm ml-auto">
                          {{ data.post_date }}
                        </label>
                      </div>

                      <p
                        class="ligth-text title-banner-ligth-sm text-1-1-lines mb-1"
                      >
                        {{ data.post_title }}
                      </p>

                      <div
                        class="text-left text-2-lines ligth-text text-description-sm mb-0"
                        v-html="data.description"
                      ></div>

                      <v-layout justify-start>
                        <v-btn
                          :to="{
                            name: 'newsDetails',
                            params: { id: data.post_id },
                          }"
                          class="mb-8"
                          color="#fff"
                          style="color: #3957b9"
                          rounded
                        >
                          <strong> ver más </strong>
                        </v-btn>
                      </v-layout>
                    </div>
                  </v-col>
                  <v-col cols="1" class="mx-0 px-0">
                    <v-icon @click="slideNext" dark> mdi-chevron-right </v-icon>
                  </v-col>
                </v-row>
              </v-img>
            </v-card>
          </slide>
          <hooper-progress
            style="margin-top: -8px; width: 96%"
            slot="hooper-addons"
          ></hooper-progress>
        </hooper>
      </div>
    </div>
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import moment from "moment";
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
  Progress as HooperProgress,
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    HooperProgress,
  },
  mixins: [alert],
  name: "Views",
  data() {
    return {
      isMobile: false,
      listView: [],
      newsGrid: [],      
      pagination: {
        page: 1,
        totalVisible: 10,
        length: null,
      },
      userInfo:{},
      token: "",
      infoToken: "",
    };
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        //this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        var docList = this.infoToken.document;
        //console.log(this.infoToken);

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getView() {
      this.loadingAll = true;
      let cc; 
      let loginId; 
      // Llamada al método getInfoUser() para obtener la información del usuario
      this.getInfoUser()
        .then((userInfo) => {
          cc = userInfo.document_number;
          loginId = userInfo.profile.id;          
          return Api.noAuth().getView2(cc, loginId);
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.cod == 0) {
            this.listView = data.data;
            let counter = 0;
            for (let i = 0; i < data.data.length; i++) {
              let date = moment(data.data[i].post_date).format("L");
              data.data[i].post_date = date;
              if (data.data[i].tags != null && data.data[i].tags.length > 0) {
                data.data[i].tagName = data.data[i].tags[0];
              } else {
                data.data[i].tagName = "Otro";
              }
              let start = i;
              let end = i + 4;
              if (start === counter) {
                let subArray = data.data.slice(start, end);
                this.newsGrid.push(subArray);
                counter = end;
              }
            }
            this.pagination.length = this.newsGrid.length;
            // console.log("******************");
            // this.newsGrid.forEach(item => {
            //   console.log(item);
            // });
            // console.log("******************");
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },   

    slidePrev() {
      if (screen.width >= 1023) {
        this.$refs.carousel.slidePrev();
      } else {
        this.$refs.carousel_mobile.slidePrev();
      }
    },
    slideNext() {
      if (screen.width >= 1023) {
        this.$refs.carousel.slideNext();
      } else {
        this.$refs.carousel_mobile.slideNext();
      }
    },
    getInfoUser() {
      this.loadingAll = true;
      return new Promise((resolve, reject) => {
        Api.Auth()
          .currentUser(this.token)
          .then((res) => {
            if (res.data.cod == 0) {
              this.userInfo = res.data.data;
              resolve(this.userInfo);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error); // Rechaza la promesa en caso de error
          })
          .finally(() => {
            this.loadingAll = false;
          });
      });
    }
  },
  mounted() {    
    this.getInfoUser();
    this.getView();
  },
  created() {
    this.checkIfMobile();
    this.getToken();

    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style lang="less">
@import "../../assets/css/main";
.text-title-news-grid {
  text-align: left;
  font-weight: 300;
  font-size: 23px;
  line-height: 37px;
  color: #353535;
  font-family: "RobotoRegular";
}

.news-grid__pagination .v-pagination .v-pagination__item {
  height: 25px !important;
  min-width: 25px !important;
  font-size: 16px !important;
  font-family: "RobotoRegular";
}

.news-grid__pagination .v-pagination .v-pagination__navigation {
  box-shadow: none !important;
  background-color: transparent !important;
  // height: 18px !important;
  // width: 18px !important;
}

.mb-sm-index-grid {
  display: none; // esto estaba en blockn
}

.news-grid__hover-card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.mdi-chevron-right-circle-outline::before {
  // content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle-outline::before {
  // content: "\F0B28";
  color: #466be3;
}

.v-icon--disabled::before {
  color: #c6c6c6 !important;
}

.news-grid__hidden {
  visibility: hidden;
}

.news-grid__mobile {
  display: none;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-index-grid {
    display: none;
  }
  .mb-sm-index-grid {
    display: block;
    text-align: initial;
  }

  .news-grid__mobile {
    display: block;
  }

  .news-grid__desktop {
    display: none;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-index-grid {
    display: none;
  }
  .mb-sm-index-grid {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-index-grid {
    display: none;
  }
  .mb-sm-index-grid {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-index-grid {
    display: none;
  }
  .mb-sm-index-grid {
    display: block;
    text-align: initial;
  }
}
</style>
